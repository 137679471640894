import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import UMLogo from './um-its-logo-white.png'
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';

export default function ButtonAppBar({ uniqname }) {
  const output = uniqname ? uniqname : 'Not logged in';

  return (
    <Box sx={{backgroundColor:"#00274C"}} display="flex" padding={4}>
       <Container>
     
     <img src={UMLogo}/>
   </Container>

    </Box>
   

    // <Box sx={{ flexGrow: 1 }}>
    //   <AppBar position="static">
    //     <Toolbar disableGutters>
    //       <Stack
    //         direction="row"
    //         alignItems="center"
    //         justifyContent="space-between"
    //         sx={{ width: '100%', px: 2 }}
    //       >
    //         <IconButton edge="start" sx={{ mr: 2 }}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="40"
    //             viewBox="0 0 294.32812 212.39062"
    //             width="40"
    //           >
    //             <g transform="matrix(1.25 0 0 -1.25 -123.87 786.33)">
    //               <g transform="matrix(2.5 0 0 2.5 -323.12 -814.35)">
    //                 <path
    //                   d="m234.43 576.97-18.42-25.467-18.389 25.467h-28.368v-20.011h7.027v-27.304h-7.027v-20.01h36.173v20.01h-7.495v15.67l17.982-24.726 18.207 24.747v-15.691h-7.495v-20.01h36.173v20.01h-7.027v27.304h7.027v20.011h-28.368z"
    //                   fill="#00274c"
    //                 />
    //                 <path
    //                   d="m261.29 558.47v16.998h-26.092l-19.191-26.532-19.157 26.532h-26.092v-16.998h7.027v-30.317h-7.027v-16.998h33.161v16.998h-7.496v21.807l19.495-26.806 19.707 26.785v-21.786h-7.495v-16.998h33.16v16.998h-7.027v30.317h7.027"
    //                   fill="#f5c300"
    //                 />
    //               </g>
    //             </g>
    //           </svg>
    //         </IconButton>

    //         <Typography
    //           variant="h5"
    //           component="div"
    //           sx={{ textAlign: { xs: 'center', sm: 'left' } }}
    //         >
    //           <b>U-M Information Technology Services</b>
    //         </Typography>

    //         <Typography
    //           variant="h6"
    //           component="div"
    //           sx={{ flexGrow: 1, textAlign: 'right' }}
    //         >
    //           {output}
    //         </Typography>
    //       </Stack>
    //     </Toolbar>
    //   </AppBar>
    // </Box>
  );
}